import React from 'react'

const Hero = () => {
  return (
    <>
    <div className='container-fluid w-full flex flex-wrap items-center justify-center pt-60'>
      <h1 className='text-3xl'>STAY TUNED, FOR</h1>
      <h1 className='text-3xl ml-2 text-[#d4af37]'> SOMETHING</h1>
      <h1 className='text-3xl ml-2'>AWESOME</h1>      
    </div>
    </>
   
  )
}

export default Hero
    
    // <div class="flex justify-center items-center h-screen">
    //     <img src="https://picsum.photos/id/1/200/300" alt="screenshot" />
    // </div>
