import React from 'react'
import Logo from '../assets/HZL1.png';

const Navbar = () => {
  return (
    <nav className="relative w-full flex flex-wrap items-center justify-between py-3">
        <div className="container-fluid w-full flex flex-wrap items-center justify-center">
            <div className="container-fluid">
                <a target="_blank" rel="noopener noreferrer" className="text-xl" href="https://www.instagram.com/haizilformen/">
                <img src={Logo} alt="Logo" style={{width: "50px", height: "40px"}} />
                </a>
            </div>
        </div>
    </nav>
  )
}

export default Navbar