import React from 'react';
import Footer from './components/Footer';
import Hero from './components/Hero'
import Navbar from './components/Navbar';
import Particles from './components/Particles';


function App() {
  return (
    <>
    <Particles id="tsparticles" />
    <Navbar />
    <Hero />
    <Footer />
    </>
  );
}

export default App;
