import React from 'react'
import Logo from '../assets/HZL1.png';

const Footer = () => {
    
  return (
 <>

    <div className="max-w-2xl mx-auto text-white py-10">
        <div className="text-center">
            <h3 className="text-3xl mb-3"> FOLLOW OUR INSTAGRAM </h3>
            <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/haizilformen/'><p> @haizilformen </p></a>
            {/* <div class="flex justify-center my-10">
                <div class="flex items-center border w-auto rounded-lg px-4 py-2 mx-2">
                <img src={Logo} alt="Logo" style={{width: "50px", height: "40px"}} />
                    <div class="text-left ml-3">
                        <p class='text-xs text-gray-200'>Download on </p>
                        <p class="text-sm md:text-base"> Google Play Store </p>
                    </div>
                </div>
                <div class="flex items-center border w-auto rounded-lg px-4 py-2 mx-2">
                <img src={Logo} alt="Logo" style={{width: "50px", height: "40px"}} />
                    <div class="text-left ml-3">
                        <p class='text-xs text-gray-200'>Download on </p>
                        <p class="text-sm md:text-base"> Apple Store </p>
                    </div>
                </div>
            </div> */}
        </div>
        <div className="mt-52 flex flex-col md:flex-row md:justify-between items-center text-sm text-gray-400">
            <p className="order-2 md:order-1 mt-8 md:mt-0"> &copy; Haizil For Men 2022 </p>
            <a target="_blank" rel="noopener noreferrer" href='https://www.batrisyiaherbal.co.id'><p className="order-2 md:order-1 mt-8 md:mt-0"> Batrisyia Herbal Indonesia </p></a>
            {/* <div class="order-1 md:order-2">
                <span class="px-2">About us</span>
                <span class="px-2 border-l">Contact us</span>
                <span class="px-2 border-l">Privacy Policy</span>
            </div> */}
        </div>
    </div>

</>
  )
}

export default Footer